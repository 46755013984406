.swiper-pagination-bullet {
  background-color: #00000000; /* Custom dot color */
  opacity: 1;
  width: 12px;
  height: 12px;
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: #00000000; /* Active dot color */
}

.custom-arrow-prev, .custom-arrow-next {
  color: #00000000; /* Custom arrow color */
  font-size: 2rem;
}

.custom-arrow-prev:hover, .custom-arrow-next:hover {
  color: #00000000; /* Hover effect on arrows */
}
